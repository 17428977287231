<!--
 * @Author: xiaokun
 * @Date: 2019-12-24 09:51:06
 * @LastEditors: huangwj
 * @LastEditTime: 2020-03-08 19:27:36
 * @Description: 布局
 -->
<template>
  <div class="layout">
    <template v-if="$route.meta.keepAlive">
      <keep-alive>
        <router-view />
      </keep-alive>
    </template>
    <template v-else>
      <router-view />
    </template>
  </div>
</template>

<script>
export default {
  name: 'Layout',
  components: {},
  data () {
    return {
    }
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
.layout {
  min-height: 100%;
}
</style>
